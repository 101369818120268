import React from 'react';
import { DataItem } from '../services/model'; // Ensure the DataItem type is exported from App
import './RecipeDetail.css';

interface RecipeDetailProps {
  item: DataItem;
  onBack: () => void;
}

const RecipeDetail: React.FC<RecipeDetailProps> = ({ item, onBack }) => {
  return (
    <div className="data-item-detail">
      <div className="duo-col-layout">
        <div className="img-col">
          <button className="rtn-btn" onClick={onBack}>back</button>
          <img src={item.img} alt={item.name}/>
        </div>

        <div className="txt-col">
          <h2 className='itm-ttl'>{item.name}</h2>
          <p>{item.description}</p>
          <div className="tags">
            {item.tags.map(tag => (
              <div className="tag">{tag}</div>
              // <span key={tag} className="tag">{tag}, </span>
            ))}
          </div>

          <h2 className="section-title">What to get</h2>
          <p className="section-header">Ingredients</p>
          <ul className="item-lst">
            {item.ingredients.map(ing => (
              <li key={ing} className="ing">{ing}</li>
            ))}
          </ul>

          <p className="section-header">Seasonings</p>
          <ul className="item-lst">
            {item.seasonings.map(seas => (
              <li key={seas} className="seas">{seas}</li>
            ))}
          </ul>
        </div>

        
      </div>

      <h2 className="section-title">Cooking Instructions</h2>
      <ul className="item-lst">
        {item.directions.map(dir => (
          <li key={dir} className="dir">{dir}</li>
        ))}
      </ul>

      {/* <h2 className="section-title">Credits:</h2> */}

    </div>
  );
};

export default RecipeDetail;
